import React, { useState } from "react"
import _ from "lodash"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"

import { withStyles } from "@material-ui/core/styles"
import { useStyles } from "../utils/styles"

const CELL_SIZE = 81

const initialStateFromProblem = problem => {
  const initialState = _.map([...Array(CELL_SIZE).keys()], index => {
    return 0
  })
  _.forEach(problem, p => {
    const [y, x, num] = p
    initialState[y * 9 + x] = num
  })

  return initialState
}

const currentStateFromProblem = problem => {
  const currentState = _.map([...Array(CELL_SIZE).keys()], index => {
    return []
  })
  _.forEach(problem, p => {
    const [y, x, num] = p
    currentState[y * 9 + x].push(num)
  })

  return currentState
}

const StyledButtonGroup = withStyles(theme => ({
  grouped: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "20px",
    },
  },
  groupedOutlined: {
    [theme.breakpoints.down("sm")]: {
      padding: "5px 12px",
    },
  },
}))(ButtonGroup)

const StyledButton = withStyles(theme => ({
  containedPrimary: {
    backgroundColor: "#2fa7e0",
    "&:hover": {
      backgroundColor: "#0083c1",
    },
  },
  outlinedPrimary: {
    color: "#0083c1",
  },
}))(Button)

const InputButtonGroup = ({ numbers, handleSelect }) => {
  const classes = useStyles()

  return (
    <Box className={classes.boardController}>
      <StyledButtonGroup color="primary" aria-label="button group">
        {_.map([...Array(9).keys()], index => {
          return (
            <StyledButton
              variant={
                _.includes(numbers, index + 1) ? "contained" : "outlined"
              }
              key={index}
              onClick={handleSelect(index + 1)}
              tabIndex="-1"
            >
              {index + 1}
            </StyledButton>
          )
        })}
      </StyledButtonGroup>
    </Box>
  )
}

const BoardMain = ({
  initialState,
  currentState,
  selectedIndex,
  handleClick,
  handleKeyDown,
  solved,
}) => {
  const classes = useStyles()
  const cells = _.map([...Array(CELL_SIZE).keys()], index => {
    const num = initialState[index]
    const y = Math.floor(index / 9)
    const x = index % 9
    const cn = clsx(
      classes.cell,
      {
        [classes.cellBorderRight]: x === 2 || x === 5,
      },
      {
        [classes.cellBorderBottom]: y === 2 || y === 5,
      },
      {
        [classes.cellFixed]: num !== 0 || solved,
      },
      {
        [classes.cellDigitSingle]: currentState[index].length === 1,
      },
      {
        [classes.cellFocused]: index === selectedIndex,
      }
    )

    if (num === 0) {
      if (currentState[index].length === 1) {
        return (
          <Box
            key={index}
            className={cn}
            tabIndex="0"
            onClick={handleClick(index)}
            onKeyDown={handleKeyDown}
          >
            {currentState[index][0]}
          </Box>
        )
      } else {
        const nums = _.map(currentState[index], n => {
          const cn = clsx(
            classes.cellDigit,
            { [classes.cellDigit1]: n === 1 },
            { [classes.cellDigit2]: n === 2 },
            { [classes.cellDigit3]: n === 3 },
            { [classes.cellDigit4]: n === 4 },
            { [classes.cellDigit5]: n === 5 },
            { [classes.cellDigit6]: n === 6 },
            { [classes.cellDigit7]: n === 7 },
            { [classes.cellDigit8]: n === 8 },
            { [classes.cellDigit9]: n === 9 }
          )
          return (
            <span className={cn} key={n}>
              {n}
            </span>
          )
        })

        return (
          <Box
            key={index}
            className={cn}
            tabIndex="0"
            onClick={handleClick(index)}
            onKeyDown={handleKeyDown}
          >
            {nums}
          </Box>
        )
      }
    } else {
      return (
        <Box key={index} className={cn}>
          {num}
        </Box>
      )
    }
  })

  return <Box className={classes.boardMain}>{cells}</Box>
}

const isSolved = (currentState, answer) => {
  return _.every([...Array(CELL_SIZE).keys()], index => {
    return (
      currentState[index].length === 1 &&
      currentState[index][0] === answer[index]
    )
  })
}

export default function Board({ sudoku, tacticNo }) {
  const classes = useStyles()
  const json = JSON.parse(sudoku.json)
  const problem = json.problem
  const answer = json.answer
  const initialState = initialStateFromProblem(problem)
  const [currentState, setCurrentState] = useState(
    currentStateFromProblem(problem)
  )
  const [numbers, setNumbers] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(
    _.findIndex(initialState, st => {
      return st === 0
    })
  )
  const [solved, setSolved] = useState(false)
  const KEYCODE_TAB = 9
  const KEYCODE_0 = 48
  const KEYCODE_1 = 49
  const KEYCODE_9 = 57
  const KEYCODE_T0 = 96
  const KEYCODE_T1 = 97
  const KEYCODE_T9 = 105
  const KEYCODE_LEFT = 37
  const KEYCODE_UP = 38
  const KEYCODE_RIGHT = 39
  const KEYCODE_DOWN = 40

  const handleCellClick = index => () => {
    setSelectedIndex(index)
    setNumbers(currentState[index])
  }
  const updateNumbers = num => {
    let newNumbers
    if (_.includes(numbers, num)) {
      newNumbers = _.filter(numbers, n => {
        return n !== num
      })
    } else {
      newNumbers = _.sortBy(_.concat([num], numbers))
    }
    setNumbers(newNumbers)
    currentState[selectedIndex] = newNumbers
    setCurrentState(currentState)

    isSolved(currentState, answer) ? setSolved(true) : setSolved(false)
  }
  const handleSelect = num => () => {
    updateNumbers(num)
  }
  const moveCursor = (keyCode, selectedIndex) => {
    const moveLeft = selectedIndex => {
      const index = _.findLast(_.range(selectedIndex), i => {
        return initialState[i] === 0
      })
      if (!_.isUndefined(index)) {
        handleCellClick(index)()
      }
    }
    const moveUp = selectedIndex => {
      const x = selectedIndex % 9
      const index = _.findLast(_.range(x, selectedIndex, 9), i => {
        return initialState[i] === 0
      })
      if (!_.isUndefined(index)) {
        handleCellClick(index)()
      }
    }
    const moveRight = selectedIndex => {
      const index = _.find(_.range(selectedIndex + 1, CELL_SIZE), i => {
        return initialState[i] === 0
      })
      if (!_.isUndefined(index)) {
        handleCellClick(index)()
      }
    }
    const moveDown = selectedIndex => {
      const index = _.find(_.tail(_.range(selectedIndex, CELL_SIZE, 9)), i => {
        return initialState[i] === 0
      })
      if (!_.isUndefined(index)) {
        handleCellClick(index)()
      }
    }
    switch (keyCode) {
      case KEYCODE_LEFT:
        moveLeft(selectedIndex)
        break
      case KEYCODE_UP:
        moveUp(selectedIndex)
        break
      case KEYCODE_RIGHT:
        moveRight(selectedIndex)
        break
      case KEYCODE_DOWN:
        moveDown(selectedIndex)
        break
      default:
        throw new Error("never happen")
    }
  }
  const handleKeyDown = e => {
    // disable Tab
    if (e.keyCode === KEYCODE_TAB) {
      e.stopPropagation()
      e.preventDefault()
      return false
    }

    // cursor move
    if (e.keyCode >= KEYCODE_LEFT && e.keyCode <= KEYCODE_DOWN) {
      e.stopPropagation()
      e.preventDefault()
      moveCursor(e.keyCode, selectedIndex)
      return false
    }

    // input number
    if (
      (e.keyCode >= KEYCODE_1 && e.keyCode <= KEYCODE_9) ||
      (e.keyCode >= KEYCODE_T1 && e.keyCode <= KEYCODE_T9)
    ) {
      const num =
        e.keyCode >= KEYCODE_1 && e.keyCode <= KEYCODE_9
          ? e.keyCode - KEYCODE_0
          : e.keyCode - KEYCODE_T0
      updateNumbers(num)
    } else {
      return false
    }
  }

  return (
    <Box className={classes.board}>
      <Box className={classes.boardTitle}>
        Level {tacticNo === 0 ? "?" : tacticNo}
      </Box>
      <BoardMain
        currentState={currentState}
        solved={solved}
        initialState={initialState}
        selectedIndex={selectedIndex}
        handleClick={handleCellClick}
        handleKeyDown={handleKeyDown}
      />
      <InputButtonGroup numbers={numbers} handleSelect={handleSelect} />
    </Box>
  )
}
