import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Container from "@material-ui/core/Container"

import { useStyles } from "../utils/styles"
import { siteURL, siteTitle } from "../config/config"
import { Navbar } from "../components/navbar"
import { Footer } from "../components/footer"
import JsonLD, { JSONLD_WEBSITE } from "../components/jsonld"
import Ogp, { OGP_WEBSITE } from "../components/ogp"
import Player from "../components/Player"
import GoogleFont from "../components/GoogleFont"

export default ({ data }) => {
  const index = data.contentfulIndex
  const title = index.title + " | " + siteTitle
  const description = index.description
  const eyecatchURL = index.eyecatch.file.url
  const classes = useStyles()

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <GoogleFont />
      </Helmet>
      <Navbar />
      <JsonLD
        headline={title}
        description={description}
        articleImageURL={eyecatchURL}
        datePublished={index.createdAt}
        dateModified={index.updatedAt}
        canonicalURL={siteURL}
        breadcrumbList={[]}
        type={JSONLD_WEBSITE}
      />
      <Ogp
        type={OGP_WEBSITE}
        title={title}
        description={description}
        url={siteURL}
        imageURL={eyecatchURL}
      />
      <div className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Location>
            {({ location }) => (
              <Player
                search={
                  location.search ? queryString.parse(location.search) : {}
                }
              />
            )}
          </Location>
          <a
            href="/techniques/"
            className="block bold mx-auto my4 puzzledev-btn"
            target="_blank"
            rel="noopener"
          >
            テクニックの解説を読む
          </a>
        </Container>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    contentfulIndex(slug: { eq: "index" }) {
      slug
      title
      description
      eyecatch {
        file {
          url
        }
      }
      createdAt
      updatedAt
    }
  }
`
