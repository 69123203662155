import React from "react"
import { Helmet } from "react-helmet"
import { authorName, publisherName, siteLogo } from "../config/config"

export const JSONLD_WEBSITE = "WebSite"
export const JSONLD_ARTICLE = "Article"

export default ({
  headline,
  description,
  articleImageURL,
  canonicalURL,
  datePublished,
  dateModified,
  breadcrumbList,
  type = JSONLD_ARTICLE,
}) => {
  const author = {
    "@type": "Person",
    name: authorName,
  }

  const image = {
    "@type": "ImageObject",
    url: articleImageURL,
    width: "1200",
    height: "600",
  }

  const publisher = {
    "@type": "Organization",
    name: publisherName,
    logo: {
      "@type": "ImageObject",
      url: siteLogo,
      width: "600",
      height: "60",
    },
  }

  const jsonLdConfigs = [
    {
      "@context": "https://schema.org",
      "@type": type,
      headline,
      description,
      image,
      author,
      publisher,
      datePublished,
      dateModified,
      mainEntityOfPage: canonicalURL,
    },
  ]

  if (breadcrumbList.length > 0) {
    jsonLdConfigs.push({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbList.map((item, index) => {
        return {
          "@type": "ListItem",
          position: index + 1,
          name: item.name,
          item: item.url,
        }
      }),
    })
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonLdConfigs)}
      </script>
    </Helmet>
  )
}
