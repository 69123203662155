import React from "react"
import { Helmet } from "react-helmet"
import { siteTitle, facebookAppId, twitterUserName } from "../config/config"

export const OGP_WEBSITE = "website"
export const OGP_ARTICLE = "article"

export default function Ogp({ type, title, description, url, imageURL }) {
  return (
    <Helmet>
      <meta property="og:title" content={title || siteTitle} />
      <meta property="og:description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={imageURL} />
      <meta property="fb:app_id" content={facebookAppId} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={`@${twitterUserName}`} />
    </Helmet>
  )
}
