import React, { useState, useEffect } from "react"
import _ from "lodash"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Board from "./Board"
import { useStyles } from "../utils/styles"
import { fetchApi } from "../utils/fetchApi"

export default function Player({ search }) {
  const classes = useStyles()
  const [dailies, setDailies] = useState([])
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    lazyLoad: "ondemand",
    dotsClass: clsx("slick-dots", classes.slickDots),
  }
  const sliderRef = React.createRef()
  const handlePrev = () => {
    sliderRef.current.slickPrev()
  }
  const handleNext = () => {
    sliderRef.current.slickNext()
  }

  useEffect(() => {
    let cleanupFlag = false

    const fetchDailies = async () => {
      const responseData = await fetchApi(null, `/dailies/`)
      if (!cleanupFlag) {
        setDailies(_.sortBy(responseData, ["tacticNo"]))
      }
    }

    const fetchProblem = async () => {
      const responseData = await fetchApi(
        null,
        `/problems/${search["problem_hex"]}`
      )
      if (!cleanupFlag) {
        const result = { tacticNo: 0 }
        result["json"] = responseData
        setDailies([result])
      }
    }

    _.isUndefined(search["problem_hex"]) ? fetchDailies() : fetchProblem()
    return () => {
      cleanupFlag = true
    }
  }, [setDailies, search])

  return (
    <Box className={classes.player}>
      <IconButton aria-label="left" onClick={handlePrev}>
        <KeyboardArrowLeftIcon fontSize="large" />
      </IconButton>
      <Box className={classes.sliderContainer}>
        <Slider ref={sliderRef} {...settings}>
          {_.map(dailies, daily => {
            return (
              <Board
                sudoku={daily.json}
                key={daily.tacticNo}
                tacticNo={daily.tacticNo}
              />
            )
          })}
        </Slider>
      </Box>
      <IconButton aria-label="right" onClick={handleNext}>
        <KeyboardArrowRightIcon fontSize="large" />
      </IconButton>
    </Box>
  )
}
